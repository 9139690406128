import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "primeicons/primeicons.css";
import AOS from "aos";
import "aos/dist/aos.css";

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  created() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
